import "../styles/index.scss"
import Foundation from 'foundation-sites'
import $ from 'jquery'
window.$ = $ // expose jquery to global

$(document).foundation()

$(document).ready(function() {
  $('header.top #mobile-menu').on('toggled.zf.responsiveToggle', function() {
  	$('header.top').toggleClass('opened')
  })

  $('a').each(function() {
    if (typeof $(this).attr('href') != 'undefined') {
      if ($(this).attr('href').match('#get-started')) {
        $(this).click(function(e) {
          var aTag = $("a[name='get-started']");
          if (aTag.length > 0) {
            e.preventDefault()
            $('html,body').animate({scrollTop: aTag.offset().top - 130},'slow')
          } else {
            $(this).attr('href', $(this).attr('href').replace('#get-started',''))
          }
        })
      }
    }
  })

  $('form.signup').submit(function(e) {
    if (!$(this).find('input[type="checkbox"]').is(':checked')) {
      e.preventDefault()
      $(this).find('.error-label').removeClass('hide').text('To proceed, you must confirm to start your registry.')
    }
  })
})

